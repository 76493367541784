import { PartItem } from '@cutr/constants/cutlist';
import { useTranslation } from 'react-i18next';

import { selectActions, useCutlistState } from '@/api/store';
import { Button } from '@/primitives/Button';
import { Duplicate, Icon, Trash } from '@/primitives/Icons';

export type TableRowActionsProps = {
  part: PartItem;
  onPartDelete: () => void;
};

export const TableRowActions = ({
  part,
  onPartDelete,
}: TableRowActionsProps) => {
  const { t } = useTranslation();
  const { duplicatePart } = useCutlistState(selectActions);

  const capitalize = (s: string) => {
    return s[0].toUpperCase() + s.slice(1);
  };

  return (
    <div className="flexAlign gap-xxs alignEnd">
      {part.partType !== 'sheet' && (
        <Button
          variant="icon"
          style={{ color: ' var(--primary)' }}
          icon={<Icon icon={<Duplicate />} />}
          name="duplicate"
          onClick={() => {
            window.analytics.track(
              `Cutlist ${capitalize(part.partType)} Duplicated`,
              {
                part,
              }
            );
            duplicatePart(part);
          }}
          tooltip={t('common.cta.duplicate')}
          aria-label={t('common.cta.duplicate')}
        />
      )}
      <Button
        variant="icon"
        className="delete"
        name="delete"
        icon={<Icon icon={<Trash />} />}
        onClick={() => {
          window.analytics.track(
            `Cutlist ${capitalize(part.partType)} Removed`,
            { part }
          );
          onPartDelete();
        }}
        tooltip={t('common.cta.delete')}
        aria-label={t('common.cta.delete')}
      />
    </div>
  );
};
