import cn from 'classnames';
import React from 'react';

import styles from '../primitives/Dropdown.module.css';
import { Checkmark, Icon } from '../primitives/Icons';
import multiselectStyles from './StatusMultiselect.module.css';

export interface MultiselectOption {
  value: string;
  component: React.FC<{ isSelected?: boolean }>;
  selected?: boolean;
}

interface MultiselectProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string[];
  id: string;
  options: MultiselectOption[];
  onSelected?: (value: string[]) => void;
}

export const Multiselect = ({
  value,
  options,
  id,
  onSelected,
  ...props
}: MultiselectProps) => {
  const [expanded, setExpanded] = React.useState(false);
  const selected = options.filter((o) => o.selected).map((o) => o.value);
  const [selectedValues, setSelectedValues] =
    React.useState<string[]>(selected);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!expanded) return;

    const handleClick = (e: MouseEvent) => {
      if (
        e.target !== dropdownRef.current &&
        !dropdownRef.current?.contains(e.target as Node)
      ) {
        setExpanded(false);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [expanded]);

  const handleSelection = (code: string) => {
    setSelectedValues((prev) => {
      const newSelectedValues = prev.includes(code)
        ? prev.filter((val) => val !== code)
        : [...prev, code];
      onSelected && onSelected(newSelectedValues);
      return newSelectedValues;
    });
  };

  return (
    <div
      className={styles.dropdown}
      style={{
        backgroundColor: 'var(--white)',
      }}
      onKeyDown={(e) => {
        e.preventDefault();
        if (e.key === 'Escape') {
          setExpanded(false);
        }

        if (e.key === 'Enter') {
          const el = e.currentTarget.parentElement?.querySelector(
            '[role="option"][aria-selected="true"]'
          );
          if (!el) return setExpanded((e) => !e);

          const code = el.getAttribute('data-id');
          if (!code) return;

          const disabled = el.getAttribute('aria-disabled');
          if (disabled === 'true') {
            return;
          }

          el.setAttribute('aria-selected', 'false');
          handleSelection(code);
        }

        let els: HTMLElement[] = [];
        let found = -1;
        if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
          setExpanded(true);
          els = [
            ...(e.currentTarget.parentElement?.querySelectorAll(
              '[role="option"]'
            ) || []),
          ] as HTMLElement[];

          for (const el of els) {
            if (el.getAttribute('aria-selected') === 'true') {
              el.setAttribute('aria-selected', 'false');
              found = els.findIndex((ell) => ell === el);
              continue;
            }
          }
        }
        if (e.key === 'ArrowDown') {
          const targetEl = els[found + 1] ? els[found + 1] : els[0];
          targetEl?.setAttribute('aria-selected', 'true');
          targetEl?.scrollIntoView({
            block: 'center',
          });
        }
        if (e.key === 'ArrowUp') {
          const targetEl = els[found - 1]
            ? els[found - 1]
            : els[els.length - 1];
          targetEl?.setAttribute('aria-selected', 'true');
          targetEl?.scrollIntoView({
            block: 'center',
          });
        }
      }}
      ref={dropdownRef}
    >
      <div
        role="button"
        tabIndex={0}
        id={`dropdown-button-${id}`}
        aria-controls={`dropdown-${id}`}
        style={{
          border: '1px solid var(--gray-5)',
          backgroundColor: 'var(--white)',
          paddingRight: '0.5rem',
        }}
        onClick={() => setExpanded(!expanded)}
        {...props}
      >
        <div className="flexAlign opposites">
          <div
            style={{ lineHeight: '1.5', fontSize: '1rem', padding: '0.5rem' }}
          >
            {value}
          </div>
          <div
            className={cn(styles.ggChevronDown, expanded && styles.ggChevronUp)}
          />
        </div>
      </div>
      {expanded && (
        <div className={styles.dropdownWrapper}>
          {options && (
            <div
              className={multiselectStyles.multiselectOptions}
              role="listbox"
              id={`dropdown-${id}`}
            >
              {options.map((o) => {
                const isSelected = selectedValues.includes(o.value);
                return (
                  <div
                    role="option"
                    tabIndex={-1}
                    aria-selected={selectedValues.includes(o.value)}
                    key={`${o.value}-${id}`}
                    data-id={o.value}
                    onClick={() => handleSelection(o.value)}
                    className="flexAlign"
                  >
                    <Icon
                      icon={<Checkmark />}
                      style={{
                        visibility: isSelected ? 'visible' : 'hidden',
                      }}
                    />
                    <o.component
                      key={`${o.value}-${id}`}
                      isSelected={selectedValues.includes(o.value)}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
