import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { Address, useAddressPretty, useDeliveryAddress } from '@/api/address';
import { useCutlistState } from '@/api/store';
import { AddressInfo } from '@/blocks/AddressInfo';
import { AgentOrderHeader } from '@/blocks/AgentOrderHeader';
import Card from '@/blocks/Card';
import { OwnerInfo } from '@/blocks/OwnerInfo';
import { QuoteSummary } from '@/blocks/QuoteSummary';
import { Button } from '@/primitives/Button';
import { Field } from '@/primitives/Field';
import { useAgentUpdateCutlistAddress } from '@/queries/agent';
import { formatYYYYMMDD, getNthWorkingDayFromToday } from '@/utils/date';

import { DeliveryAddressForm } from './AccountDetails';
import styles from './AgentQuoteCheckout.module.css';

export const AgentQuoteCheckout = () => {
  const [addressEditing, setAddressEditing] = useState(false);
  return (
    <div>
      <AgentOrderHeader />
      <section className="layout">
        {/* Main content */}
        <div className={styles.orderInfo}>
          <OwnerBlock />
          <DeliveryAddressBlock
            addressEditing={addressEditing}
            setAddressEditing={setAddressEditing}
          />
          <DeliveryDateBlock />
        </div>
        {/* Aside */}
        <QuoteSummary finaliseQuoteDisabled={addressEditing} />
      </section>
    </div>
  );
};

const OwnerBlock = () => {
  const { t } = useTranslation();
  return (
    <div className={'opposites'}>
      <h3>{t('agent.orderCheckout.owner')}</h3>
      <div className={styles.blockInfo}>
        <Card>
          <OwnerInfo />
        </Card>
      </div>
    </div>
  );
};

const DeliveryDateBlock = () => {
  const minDate = formatYYYYMMDD(getNthWorkingDayFromToday(3));
  const { t } = useTranslation();
  const cutlistStore = useCutlistState();
  return (
    <div className={'opposites'} style={{ height: 'fit-content' }}>
      <h3>{t('agent.orderCheckout.deliveryDate')}</h3>
      <div className={styles.blockInfo}>
        <Field
          label={''}
          type="date"
          name="requestedDeliveryDate"
          min={minDate}
          value={cutlistStore.requestedDeliveryDate || ''}
          setProp={(value) => {
            cutlistStore.setRequestedDeliveryDate(value);
          }}
          className="fullWidth lighterBorder dateInput moreXPadding"
        />
      </div>
    </div>
  );
};

const DeliveryAddressBlock = ({
  addressEditing,
  setAddressEditing,
}: {
  addressEditing: boolean;
  setAddressEditing: (value: boolean) => void;
}) => {
  const deliveryInfo = useAddressPretty();
  const { t } = useTranslation();

  const {
    setProp: setDeliveryProp,
    init,
    ...deliveryAddress
  } = useDeliveryAddress();
  const { mutate: updateAddress } = useAgentUpdateCutlistAddress();
  const { id } = useParams();

  // store initial address to reset to if user cancels on component mount
  const [initialAddress, setInitialAddress] = useState<Address>();
  useEffect(() => {
    setInitialAddress(deliveryAddress);
  }, []);

  const handleSave = async () => {
    if (!id) return;
    try {
      updateAddress({ id, address: deliveryAddress });
      setInitialAddress(deliveryAddress);
      toast.success(t('agent.orderCheckout.deliveryAddressUpdated'));
    } catch (error) {
      toast.error(t('agent.orderCheckout.deliveryAddressUpdateError'));
    } finally {
      setAddressEditing(false);
    }
  };
  return (
    <div className={'opposites'}>
      <h3>{t('agent.orderCheckout.deliveryTo')}</h3>
      <div className={styles.blockInfo}>
        {addressEditing ? (
          <Card>
            <form onSubmit={handleSave}>
              <DeliveryAddressForm
                title={''}
                address={deliveryAddress}
                setProp={setDeliveryProp}
              />
              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                  justifyContent: 'flex-end',
                  marginTop: '-1rem',
                }}
              >
                <Button type="submit" variant="straight">
                  {t('common.cta.save')}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    init(initialAddress as Address);
                    setAddressEditing(false);
                  }}
                >
                  {t('common.cta.cancel')}
                </Button>
              </div>
            </form>
          </Card>
        ) : (
          <Card>
            <AddressInfo
              info={deliveryInfo}
              onEditAddress={() => setAddressEditing(true)}
            />
          </Card>
        )}
      </div>
    </div>
  );
};
