import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { useCutlistState } from '@/api/store';
import { Button } from '@/primitives/Button';
import { useAgentFinaliseQuote } from '@/queries/agent';

import Card from './Card';

export const QuoteSummary = ({
  finaliseQuoteDisabled = false,
}: {
  finaliseQuoteDisabled?: boolean;
}) => {
  const [isPending, setIsPending] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const { status } = useCutlistState();
  const { mutateAsync: finaliseQuote } = useAgentFinaliseQuote();

  const handleFinaliseQuote = async () => {
    if (!id) return;

    setIsPending(true);
    try {
      await finaliseQuote({ id });
    } catch (error) {
      toast.error(t('agent.orderCheckout.finalizeQuoteError'));
      console.error(error);
    }
    setIsPending(false);
  };

  return (
    <Card>
      <div>{'Price card goes here'}</div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 'var(--space-s)',
          paddingTop: 'var(--space-m)',
        }}
      >
        {status !== 'quote_ready' && (
          <Button
            onClick={handleFinaliseQuote}
            disabled={isPending || finaliseQuoteDisabled}
          >
            {t('cutlist-summary.price.total.finaliseQuote')}
          </Button>
        )}
      </div>
    </Card>
  );
};
