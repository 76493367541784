import { CutlistRequestType } from '@cutr/constants/cutlist';
import { ALL_CUSTOM_ARTICLES } from '@cutr/constants/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAgentLoggedIn, useIsLoggedIn } from '@/api/login';
import { getMaterialById } from '@/api/materials';
import { PriceData, useAggregatedPrice, usePricingStore } from '@/api/pricing';
import { useCutlistState } from '@/api/store';
import Card from '@/blocks/Card';
import { Button } from '@/primitives/Button';
import { Checkbox } from '@/primitives/Checkbox';
import { useCurrentFeatures, useIsCutrEmbedded, useThemeConfig } from '@/theme';
import { currencyFormatter } from '@/utils/format';
import { isInIframe } from '@/utils/misc';

import { InfoMessage } from './InfoMessage';
import styles from './Price.module.css';

const getPrice = (totalAmount?: number) =>
  totalAmount ? currencyFormatter(totalAmount / 100) : null;

// TODO: extract logic to hooks once we have the store defined
// grab data from various places and return it formatted as needed

type PriceOverride = {
  priceData: PriceData[];
  totalAmountExclVAT: number;
  totalAmountInclVAT: number;
};

export const EstimatedTotal = ({
  onAction,
  readOnly = false,
  priceOverride,
}: {
  onAction(requestType: CutlistRequestType): Promise<void>;
  readOnly?: boolean;
  priceOverride?: PriceOverride;
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { showPricing, manualQuote, totalAmountExclVAT } = usePricingStore();
  const { t } = useTranslation();
  const [tosErrorMessage, setTosErrorMessage] = React.useState('');
  const [tosAccepted, setTosAccepted] = React.useState(false);
  const {
    requestQuoteEnabled,
    requestQuoteMinAmount,
    upfrontPayment,
    displayExpectedDelivery,
  } = useCurrentFeatures();

  const quoteBtnEnabled = totalAmountExclVAT >= requestQuoteMinAmount;

  const onSubmitClick = (requestType: CutlistRequestType) => {
    if (!tosAccepted) {
      setTosErrorMessage(t('common.termsAndConditionsErrorMessage'));
      return;
    }
    onAction(requestType).then(() => {
      setIsLoading(false);
    });
  };

  return (
    <Card>
      {upfrontPayment && (
        <InfoMessage message={t('cutlist-summary.price.payment')} />
      )}
      {showPricing && (
        <FullPriceDataCard
          title={
            manualQuote
              ? t('cutlist-summary.price.estimated.title')
              : t('cutlist-summary.price.title')
          }
          priceOverride={priceOverride}
        />
      )}
      {!readOnly && displayExpectedDelivery && (
        <InfoMessage message={t('cutlist-summary.delivery.deliveryTime')} />
      )}

      {!readOnly && !isInIframe() && (
        <div>
          <TermsAndConditions
            tosAccepted={tosAccepted}
            setTosAccepted={setTosAccepted}
            tosErrorMessage={tosErrorMessage}
            setTosErrorMessage={setTosErrorMessage}
          />
          <p />
          <Button
            name="submitPlaceOrder"
            disabled={isLoading || !tosAccepted}
            onClick={() => {
              setIsLoading(true);
              onSubmitClick('place_order');
            }}
          >
            {t('cutlist-summary.price.total.placeOrder')}
          </Button>
          <p />

          {requestQuoteEnabled && (
            <Button
              name="submitRequestQuote"
              disabled={!(tosAccepted && quoteBtnEnabled)}
              onClick={() => onSubmitClick('request_quote')}
            >
              {t('cutlist-summary.price.total.requestQuote')}
            </Button>
          )}
        </div>
      )}
    </Card>
  );
};

export const TermsAndConditions = ({
  tosAccepted,
  setTosAccepted,
  tosErrorMessage,
  setTosErrorMessage,
}: {
  tosAccepted: boolean;
  setTosAccepted: (value: boolean) => void;
  tosErrorMessage: string;
  setTosErrorMessage: (value: string) => void;
}) => {
  const { termsAndConditions, privacyPolicy } = useThemeConfig();
  return (
    <p>
      <Checkbox
        name="tosAccepted"
        checked={tosAccepted}
        onChange={(e) => {
          if (e.currentTarget.checked) {
            setTosErrorMessage('');
          }

          setTosAccepted(e.currentTarget.checked);
        }}
        errorMessage={tosErrorMessage}
      >
        <span>
          <Trans
            i18nKey="common.termsAndConditions"
            space={' '}
            values={{
              termsAndConditions,
              privacyPolicy,
            }}
          >
            I agree to the <a target="_blank">terms and conditions</a> and{' '}
            <a target="_blank">privacy policy</a>.
          </Trans>
        </span>
      </Checkbox>
    </p>
  );
};

export const EstimatedPrice = ({
  onAction,
  isNextDisabled,
}: {
  onAction(): Promise<void>;
  isNextDisabled: boolean;
}) => {
  const { showPricing, manualQuote } = usePricingStore();
  const { t } = useTranslation();
  const isCutrEmbedded = useIsCutrEmbedded();
  const [isLoading, setIsLoading] = React.useState(false);
  const isLoggedIn = useIsLoggedIn();
  const { integrationType } = useCurrentFeatures();

  if (!showPricing && !onAction) return null;

  const getNextButtonText = () => {
    if (!isLoggedIn) {
      return t('cutlist-summary.price.total.unauthQuoteCTA');
    }

    if (integrationType === 'webshop') {
      return t('cutlist-form.alternateButtonNames.saveAndReturnToCart');
    }

    return t('common.cta.next');
  };

  return (
    <Card>
      {isLoggedIn && showPricing && (
        <FullPriceDataCard
          title={
            manualQuote
              ? t('cutlist-summary.price.estimated.title')
              : t('cutlist-summary.price.title')
          }
        />
      )}

      {!isCutrEmbedded && (
        <Button
          name="theNextButton"
          disabled={isNextDisabled || isLoading}
          onClick={() => {
            setIsLoading(true);
            onAction().finally(() => {
              setIsLoading(false);
            });
          }}
        >
          {getNextButtonText()}
        </Button>
      )}
    </Card>
  );
};

export const FullPriceDataCard = ({
  title,
  priceOverride,
}: {
  title?: string;
  priceOverride?: PriceOverride;
}) => {
  const { error, ...pricingStore } = usePricingStore();
  const priceDataFromStore = useAggregatedPrice();
  const { requestQuoteEnabled, requestQuoteMinAmount } = useCurrentFeatures();
  const { t } = useTranslation();
  const { vatRate, status } = useCutlistState();

  const isAgentLoggedIn = useAgentLoggedIn();

  const priceData = priceOverride?.priceData || priceDataFromStore;
  const hasPriceData = Boolean(priceData.length);

  const totalAmountExclVAT =
    priceOverride?.totalAmountExclVAT || pricingStore.totalAmountExclVAT;
  const totalAmountInclVAT =
    priceOverride?.totalAmountInclVAT || pricingStore.totalAmountInclVAT;
  const canRequestQuote =
    hasPriceData && totalAmountExclVAT >= requestQuoteMinAmount;

  const getUnit = (unit: string, quantity: number) => {
    if (unit === 'm') return `${quantity}m`;
    if (unit === 'piece')
      return t('cutlist-summary.price.piece', { count: quantity });
    return '';
  };

  return (
    <>
      <div className="opposites">
        <h3>{title}</h3>
        <h3 className="isPrimary" data-cy="total-order-price">
          {currencyFormatter(totalAmountExclVAT / 100)}
        </h3>
      </div>
      <div className={styles.divider}>
        {t('cutlist-summary.price.total.excludingTaxes')}
      </div>

      {error && <p className="errors">{t('cutlist-summary.price.error')}</p>}

      {!error && !hasPriceData && (
        <p className={styles.empty}>
          {t('cutlist-summary.price.estimated.placeholder')}
        </p>
      )}

      {hasPriceData && (
        <ul className={styles.cardPrice}>
          {priceData.map((item, i) => {
            return (
              <React.Fragment key={i}>
                {!['surcharges', 'material'].includes(item.category) && (
                  <li className="flexAlign opposites">
                    <span>{item.label}</span>
                    <span>{Boolean(item.cost) && getPrice(item.cost)}</span>
                  </li>
                )}

                {['surcharges'].includes(item.category) &&
                  item.items?.map((subi, i) => (
                    <li key={i} className="flexAlign opposites">
                      <span>{subi.description}</span>
                      <span>
                        {Boolean(subi.totalAmount) &&
                          getPrice(subi.totalAmount)}
                      </span>
                    </li>
                  ))}

                {['material'].includes(item.category) &&
                  item.items?.map((subi, i) => {
                    const price = getPrice(subi.totalAmount);
                    const material = getMaterialById(subi.materialId);

                    const { lengthMM, widthMM, thicknessUM } = material || {};
                    const thickness = (thicknessUM || 0) / 1000;

                    const isCustomArticle =
                      material &&
                      (
                        ALL_CUSTOM_ARTICLES as (typeof material)['articleCode'][]
                      ).includes(material.articleCode);

                    const ebSize = isCustomArticle && material.shortDescription;
                    const sizes = [lengthMM, widthMM, thickness].filter(
                      Boolean
                    );

                    const itemDimensions =
                      !isCustomArticle && Boolean(sizes.length)
                        ? `${sizes.join('x')} mm`
                        : undefined;

                    return (
                      <li key={i}>
                        <div className="flexAlign opposites">
                          <span>{subi.description}</span>
                          {/* eslint-disable-next-line i18next/no-literal-string */}
                          <span>{price ? price : <>&mdash;</>}</span>
                        </div>
                        <span>
                          {getUnit(subi.unit, subi.quantity)}
                          {(itemDimensions || ebSize) && ', '}
                          {itemDimensions || ebSize}
                          {!price && <span>&nbsp;</span>}
                          {!price && (
                            <span className={styles.manuallyQuoted}>
                              {t('cutlist-summary.manualQuote')}
                            </span>
                          )}
                        </span>
                      </li>
                    );
                  })}
              </React.Fragment>
            );
          })}
          <li className={styles.bolder}>
            <strong>{t('cutlist-summary.price.estimated.excludingVAT')}</strong>
            <strong>{currencyFormatter(totalAmountExclVAT / 100)}</strong>
          </li>
          <li style={{ height: '0.5rem' }} />
          <li className="flexAlign opposites">
            <span>
              {t('cutlist-summary.price.vatPercent', {
                vatRate,
              })}
            </span>
            <span>
              {currencyFormatter(
                (totalAmountInclVAT - totalAmountExclVAT) / 100
              )}
            </span>
          </li>

          <li className={styles.bolder}>
            <strong>{t('cutlist-summary.price.total.includingVAT')}</strong>
            <strong>{currencyFormatter(totalAmountInclVAT / 100)}</strong>
          </li>
        </ul>
      )}
      {requestQuoteEnabled && status === 'draft' && !isAgentLoggedIn && (
        <InfoMessage
          message={t(
            `cutlist-summary.price.total.${
              canRequestQuote
                ? 'customQuoteCalloutAboveThreshold'
                : 'customQuoteCalloutBelowThreshold'
            }`,
            {
              threshold: currencyFormatter(requestQuoteMinAmount / 100),
            }
          )}
        />
      )}
    </>
  );
};
