import { MaterialGroup, PricingLineItem } from '@cutr/constants/cutlist';
import { useIsMutating } from '@tanstack/react-query';
import cn from 'classnames';
import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useMaterialGroupState } from '@/api/materialsGroup';
import {
  MaterialPriceMap,
  useMaterialPriceMap,
  usePricingStore,
} from '@/api/pricing';
import { useCutlistState } from '@/api/store';
import { AgentOrderHeader } from '@/blocks/AgentOrderHeader';
import Card from '@/blocks/Card';
import { Button } from '@/primitives/Button';
import { ArrowLeft, Icon } from '@/primitives/Icons';
import { Input } from '@/primitives/Input';
import { useGetPriceOverride } from '@/queries/agent';
import { currencyFormatter } from '@/utils/format';
import { useDebounce } from '@/utils/hooks';
import { cleanNumberInput } from '@/utils/input';

import styles from './AgentQuotePrice.module.css';

export const AgentQuotePrice = (): JSX.Element => {
  return (
    <>
      <AgentOrderHeader />
      <section className="layout">
        <QuoteEditor />

        <Aside />
      </section>
    </>
  );
};

const Aside = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const priceStore = usePricingStore();
  const { mutateAsync: getPriceOverride } = useGetPriceOverride(id!);
  const { discountAmount, discountPercentage } = useCutlistState();

  usePricingFromQuote();
  const { totalAmountExclVAT } = usePricingStore();

  return (
    <aside>
      <div>
        <div>
          {currencyFormatter(totalAmountExclVAT / 100)}
          <Button
            onClick={() => {
              getPriceOverride({
                id: id!,
                discountAmount,
                discountPercentage,
                pricingLineItems: priceStore.pricingLineItems,
                save: true,
              })
                .then((data) => {
                  priceStore.setAmountVat(data.totalAmountInclVAT);
                  priceStore.setAmountNoVat(data.totalAmountExclVAT);
                  priceStore.setPricing(data.pricingLineItems);
                })
                .catch((e) => {
                  priceStore.setError(e);
                });

              navigate(`/admin/orders/${id}/quote/checkout`);
            }}
          >
            {'Go to checkout'}
          </Button>
        </div>
      </div>
    </aside>
  );
};

export function usePricingFromQuote() {
  const { id } = useParams();
  const priceStore = usePricingStore();
  const { mutateAsync: getPriceOverride } = useGetPriceOverride(id!);
  const { discountAmount, discountPercentage } = useCutlistState();

  const quantityOverrides = priceStore.pricingLineItems.map(
    (pli) => pli.quantityOverride
  );

  const debouncedOverrides = useDebounce<(number | null)[]>(
    quantityOverrides,
    500,
    quantityOverrides
  );

  React.useEffect(() => {
    if (!id) return;
    getPriceOverride({
      id,
      discountAmount,
      discountPercentage,
      pricingLineItems: priceStore.pricingLineItems,
    })
      .then((data) => {
        priceStore.setAmountVat(data.totalAmountInclVAT);
        priceStore.setAmountNoVat(data.totalAmountExclVAT);
        priceStore.setPricing(data.pricingLineItems);
      })
      .catch((e) => {
        priceStore.setError(e);
      });
  }, [...debouncedOverrides, discountAmount, discountPercentage]);
}

const QuoteEditor = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flexAlign gap-m gutter">
        <Button
          className="outline smaller"
          icon={<Icon icon={<ArrowLeft />} />}
        >
          {'Back to cutlist'}
        </Button>
        <h2>{t('agent.quoteEditor.title')}</h2>
      </div>

      <form name="quote">
        <MaterialGroupQuotes />
      </form>
    </div>
  );
};

const MaterialGroupQuotes = () => {
  const groups = useMaterialGroupState((state) => state.groups);

  return (
    <div className={styles.quoteContainer}>
      {groups.map((group) => (
        <MaterialGroupQuote key={group.id} group={group} />
      ))}
    </div>
  );
};

const MaterialGroupQuote = ({ group }: { group: MaterialGroup }) => {
  const materialsPriceMap: MaterialPriceMap = useMaterialPriceMap();

  const getTotalAmount = (lineItems: PricingLineItem[]) => {
    if (lineItems.length === 0) {
      return '€ ---.--';
    }

    const totalAmount = lineItems.reduce(
      (acc, item) => acc + item.totalAmount,
      0
    );
    return currencyFormatter(totalAmount / 100);
  };

  const currentLineItems = materialsPriceMap[group.id] || [];
  const groupTotalAmount = getTotalAmount(currentLineItems);

  const materialLineItems = currentLineItems.filter(
    (item) => item.category === 'material'
  );
  const materialTotalAmount = getTotalAmount(materialLineItems);

  const productionCostLineItems = currentLineItems.filter(
    (item) => item.category !== 'material'
  );
  const productionCostTotalAmount = getTotalAmount(productionCostLineItems);

  return (
    <Card key={group.id}>
      <QuoteHeader groupTotalAmount={groupTotalAmount} index={group.index} />
      <div className={styles.quoteBody}>
        <QuoteSection
          title={t('agent.quoteEditor.subheaders.materials')}
          subtotal={materialTotalAmount}
          lineItems={materialLineItems}
        />
        <QuoteSection
          title={t('agent.quoteEditor.subheaders.productionCosts')}
          subtotal={productionCostTotalAmount}
          lineItems={productionCostLineItems}
        />
      </div>
    </Card>
  );
};

const QuoteHeader = ({
  groupTotalAmount,
  index,
}: {
  groupTotalAmount: string;
  index: number;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.header}>
      <div className="flexAlign opposites">
        <h3>{t('agent.quoteEditor.materialNr', { count: index })} </h3>
        <h3>{'•'}</h3>
        <h3>{groupTotalAmount}</h3>
      </div>

      <MaterialQuoteActions />
    </div>
  );
};

const QuoteSection = ({
  title,
  subtotal,
  lineItems,
}: {
  title: string;
  subtotal: string;
  lineItems: PricingLineItem[];
}) => {
  const isEditable = (pricingLineItem: PricingLineItem) => {
    if (pricingLineItem.category === 'material') {
      return true;
    }

    if (pricingLineItem.category === 'sawing' && pricingLineItem.unit === 'm') {
      return true;
    }

    return false;
  };

  return (
    <>
      <div className={styles.subheader}>
        <h4>{title}</h4>
        <h4>{'•'}</h4>
        <h4>{subtotal}</h4>
      </div>

      {lineItems.map((pricingLineItem) => (
        <LineItem
          key={pricingLineItem.id}
          priceLineItem={pricingLineItem}
          isEditable={isEditable(pricingLineItem)}
        />
      ))}

      <div className={styles.emptyRow} />
    </>
  );
};

const LineItem = ({
  priceLineItem,
  isEditable = false,
}: {
  priceLineItem: PricingLineItem;
  isEditable: boolean;
}) => {
  const { setPricingLineItem } = usePricingStore();
  const isMutating = useIsMutating({ mutationKey: ['agent', 'price'] });

  const handleQuantityOverride = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPricingLineItem({
      ...priceLineItem,
      quantityOverride: Number(cleanNumberInput(e.target.value)),
    });
  };

  return (
    <>
      <span className={cn(styles.cell, styles.padStart)}>
        {priceLineItem.description}
      </span>

      {!isEditable && (
        <span className={cn(styles.cell, styles.alignEnd)}>
          {priceLineItem.quantity}
        </span>
      )}

      {isEditable && (
        <Input
          name={`${priceLineItem.id}-quantity`}
          id={`${priceLineItem.id}-quantity`}
          type="number"
          value={priceLineItem.quantityOverride || ''}
          min="1"
          step="0.1"
          required
          className={styles.editableCell}
          disabled={!!isMutating}
          onChange={handleQuantityOverride}
        />
      )}

      <strong className={cn(styles.cell, styles.alignCenter)}>{'×'}</strong>
      <span className={styles.cell}>
        {currencyFormatter(priceLineItem.unitAmount / 100)}
      </span>
      <strong className={cn(styles.cell, styles.alignCenter)}>{'='}</strong>
      <strong className={styles.cell}>
        {currencyFormatter(priceLineItem.totalAmount / 100)}
      </strong>
    </>
  );
};

const MaterialQuoteActions = () => {
  return <div></div>;
};
