import { EdgeGroove, MaterialGroup, PartItem } from '@cutr/constants/cutlist';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useActiveGroupParts } from '@/api/materialsGroup';
import { useCutlistState } from '@/api/store';
import { Button } from '@/primitives/Button';
import { Checkbox } from '@/primitives/Checkbox';
import { Input } from '@/primitives/Input';
import { cleanNumberInput } from '@/utils/input';

import MaterialGroupCard from '../PartTableCommon/MaterialGroupCard';
import { MaterialRemoval, MaterialUpdate } from '../PartTableCommon/types';
import styles from './EdgeProcessingConfiguration.module.css';
import {
  EdgeProcessingProvider,
  useEdgeProcessing,
} from './EdgeProcessingContext';
import { EdgesSelector } from './EdgeSelector';
import { GroovePreview } from './GroovePreview';
import { useSelectedPartsContext } from './SelectedPartsContext';

export const EDGE_PROCESSING_FORM_ID = 'edge-processing-form';

export const EdgeProcessingConfiguration = () => {
  return (
    <EdgeProcessingProvider>
      <EdgeProcessingContent />
    </EdgeProcessingProvider>
  );
};

const EdgeProcessingContent = () => {
  const { t } = useTranslation();
  const { position, dimensions, edgeband, roundedEdgeband, edgesToApply } =
    useEdgeProcessing();
  const { selectedParts } = useSelectedPartsContext();
  const { setPart } = useCutlistState();
  const activeGroupParts = useActiveGroupParts();

  const handleApplyEdgeProcessing = (e: React.FormEvent) => {
    e.preventDefault();

    const partsToUpdate: PartItem[] = activeGroupParts.filter((p) =>
      selectedParts.includes(p.id)
    );

    partsToUpdate.forEach((part) => {
      const newPart = { ...part };

      ['length1', 'length2', 'width1', 'width2'].forEach((edge) => {
        // @ts-expect-error
        if (!edgesToApply[edge]) return;

        if (edgeband) {
          newPart.edgebanding = {
            ...{
              length1: null,
              length2: null,
              width1: null,
              width2: null,
            },
            ...newPart.edgebanding,
            [edge]: edgeband,
          };

          if (roundedEdgeband) {
            newPart.roundedEdgeband = {
              ...newPart.roundedEdgeband,
              [edge]: true,
            };
          }
        }

        if (position !== 'none') {
          newPart.grooves = {
            ...newPart.grooves,
            [edge]: { position, ...dimensions },
          };
        }
      });

      setPart(newPart);
    });
  };

  const getButtonTooltip = () => {
    if (selectedParts.length === 0) return t('agent.atLeastOnePartTooltip');
    if (Object.values(edgesToApply).every((v) => !v))
      return t('agent.atLeastOneEdgeTooltip');
    return undefined;
  };

  return (
    <>
      <h3>{t('agent.quoteFlow.edgeProcessing')}</h3>
      <p>{t('agent.quoteFlow.edgeProcessingDescription')}</p>
      <div className={styles.grid}>
        <div>
          <EdgeGrooveConfiguration />
          <EdgebandConfiguration />
          <div className={styles.previewGrid}>
            <div className={styles.preview}>
              <h4 className={styles.subtitle}>{t('agent.preview.title')}</h4>
              <GroovePreview position={position} />
            </div>
            <div className={styles.preview}>
              <h4 className={styles.subtitle}>
                {t('agent.preview.toApplyTo')}
              </h4>
              <EdgesSelector />
            </div>
          </div>
          <p />
          <Button
            type="submit"
            className="outline"
            onClick={handleApplyEdgeProcessing}
            form={EDGE_PROCESSING_FORM_ID}
            tooltip={getButtonTooltip()}
            disabled={
              selectedParts.length === 0 ||
              Object.values(edgesToApply).every((v) => !v)
            }
          >
            {t('agent.applyEdgeProcessing')}
          </Button>
        </div>
      </div>
    </>
  );
};

export const EdgeGrooveConfiguration = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h4 className={styles.subtitle}>{t('agent.edgeGroove.title')}</h4>
      <EdgeGroovePosition />
      <EdgeGrooveDimensions />
    </div>
  );
};

const EdgeGroovePosition = () => {
  const { t } = useTranslation();
  const { position, setPosition } = useEdgeProcessing();

  const handlePositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPosition(event.target.value as EdgeGroove['position']);
  };

  return (
    <div className={styles.inputContainer}>
      <strong>{t('agent.edgeGroove.position.label')}</strong>
      <div className="flexAlign" style={{ paddingTop: 'var(--space-xs)' }}>
        {['none', 'top', 'side', 'bottom'].map((option) => (
          <div className="flexAlign gap-xs" key={option}>
            <input
              name="groove-position"
              form={EDGE_PROCESSING_FORM_ID}
              id={`groove-position-${option}`}
              type="radio"
              value={option}
              style={{
                appearance: 'auto',
                margin: 0,
                accentColor: 'var(--primary)',
              }}
              onChange={(e) => handlePositionChange(e)}
              checked={position === option}
              required
            />
            <label htmlFor={`groove-position-${option}`}>
              {t(`agent.edgeGroove.position.${option}`)}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

const EdgeGrooveDimensions = () => {
  const { t } = useTranslation();
  const { position, dimensions, setDimensions } = useEdgeProcessing();
  const { offset, width, depth } = dimensions;

  const handleDimensionChange =
    (dimension: 'A' | 'B' | 'C') =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const cleanedValue = cleanNumberInput(event.target.value);
      // @ts-expect-error
      setDimensions((prev) => ({ ...prev, [dimension]: cleanedValue }));
    };

  const getPlaceholderA = (position: EdgeGroove['position']) => {
    if (position === 'top')
      return t('agent.edgeGroove.dimension.placeholderOffsetSide');
    if (position === 'side')
      return t('agent.edgeGroove.dimension.placeholderOffsetTop');
    if (position === 'bottom')
      return t('agent.edgeGroove.dimension.placeholderOffsetSide');
    return '';
  };

  const getPlaceholderB = () => {
    return t('agent.edgeGroove.dimension.placeholderWidth');
  };

  const getPlaceholderC = () => {
    return t('agent.edgeGroove.dimension.placeholderDepth');
  };

  const dimensionsMeta = [
    {
      key: 'groove-dimension-A',
      dimension: 'offset',
      value: offset,
      placeholder: getPlaceholderA(position),
    },
    {
      key: 'groove-dimension-B',
      dimension: 'width',
      value: width,
      placeholder: getPlaceholderB(),
    },
    {
      key: 'groove-dimension-C',
      dimension: 'depth',
      value: depth,
      placeholder: getPlaceholderC(),
    },
  ];

  return (
    <div className={styles.inputContainer}>
      <strong>{t('agent.edgeGroove.dimension.label')}</strong>
      <div className="flexAlign" style={{ paddingTop: 'var(--space-xs)' }}>
        {dimensionsMeta.map(({ key, dimension, value, placeholder }) => (
          <div className="flexAlign gap-xs" key={key}>
            <label htmlFor={key} className="textStrong">
              {t(`agent.edgeGroove.dimension.${dimension}`)}
              {position !== 'none' ? (
                '*'
              ) : (
                <span style={{ visibility: 'hidden' }}>*</span>
              )}
            </label>
            <Input
              name="groove-dimension"
              form={EDGE_PROCESSING_FORM_ID}
              id={key}
              required={position !== 'none'}
              type="number"
              value={value}
              min="0"
              step="0.1"
              placeholder={placeholder}
              disabled={position === 'none'}
              onChange={(e) => {
                handleDimensionChange(dimension as 'A' | 'B' | 'C')(e);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const EdgebandConfiguration = () => {
  const { t } = useTranslation();
  const { edgeband, setEdgeband, roundedEdgeband, setRoundedEdgeband } =
    useEdgeProcessing();

  const onUpdateEdgeband: MaterialUpdate = (fields) => {
    setEdgeband(fields.edgeband || '');
  };

  const onRemoveEdgeband: MaterialRemoval = () => {
    setEdgeband('');
    setRoundedEdgeband(false);
  };

  const handleRoundedEdgebandChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRoundedEdgeband(event.target.checked);
  };

  return (
    <div>
      <h4 className={styles.subtitle}>
        {t('agent.edgebandConfiguration.title')}
      </h4>

      <div className={styles.inputContainer}>
        <MaterialGroupCard
          i18nKey="edgeband"
          field="edgeband"
          group={{ edgeband } as MaterialGroup}
          onUpdateMaterial={onUpdateEdgeband}
          onRemoveMaterial={onRemoveEdgeband}
        />
      </div>

      <div className={styles.inputContainer}>
        <Checkbox
          checked={roundedEdgeband}
          onChange={handleRoundedEdgebandChange}
          className="labelBefore"
          disabled={!edgeband}
          isSwitch
        >
          {t('agent.edgebandConfiguration.edgebanding.roundedEdgeband')}
        </Checkbox>
      </div>
    </div>
  );
};
