import { PartGrooves, PartItem } from '@cutr/constants/cutlist';
import { useTranslation } from 'react-i18next';

import { getMaterial } from '@/api/materials';
import { CircledCheckmark2, CutCircle, Icon } from '@/primitives/Icons';

import styles from './EdgeInfo.module.css';

export const EdgeInfo = ({
  part,
  side,
}: {
  part: PartItem;
  side: keyof PartGrooves;
}) => {
  const { t } = useTranslation();

  const hasEdgeProcessing =
    part.edgebanding?.[side] ||
    part.roundedEdgeband?.[side] ||
    part.grooves?.[side];

  return (
    <div className={styles.container}>
      <Icon
        style={{
          color: hasEdgeProcessing ? 'var(--primary)' : undefined,
        }}
        icon={hasEdgeProcessing ? <CircledCheckmark2 /> : <CutCircle />}
      />

      <div className={styles.tooltip}>
        {!hasEdgeProcessing && <span>{t('agent.noEdgeProcessing')}</span>}
        {hasEdgeProcessing && <EdgePreview part={part} side={side} />}
        <div className={styles.base} />
      </div>
    </div>
  );
};

const EdgePreview = ({
  part,
  side,
}: {
  part: PartItem;
  side: keyof PartGrooves;
}) => {
  const { t } = useTranslation();
  const groove = part.grooves?.[side];
  const edgebanding = part.edgebanding?.[side];
  const roundedEdgeband = part.roundedEdgeband?.[side];
  const material = getMaterial(edgebanding);

  const offsetLabel =
    groove?.position === 'side'
      ? t('agent.tooltip.offsetLabelSide')
      : t('agent.tooltip.offsetLabel');

  return (
    <div>
      {groove?.position && (
        <>
          <strong>{t('agent.edgeGroove.title')}</strong>
          <dl>
            <dt>{t('agent.edgeGroove.position.label')}</dt>
            <dd>{t(`agent.edgeGroove.position.${groove.position}`)}</dd>
            <dt>{offsetLabel}</dt>
            <dd>{groove.offset}</dd>
            <dt>{t('agent.tooltip.width')}</dt>
            <dd>{groove.width}</dd>
            <dt>{t('agent.tooltip.depth')}</dt>
            <dd>{groove.depth}</dd>
          </dl>
        </>
      )}
      {edgebanding && (
        <>
          <strong>{t('agent.edgebandConfiguration.title')}</strong>
          <dl style={{ gridTemplateColumns: '1fr 2fr' }}>
            <dt>{t('agent.tooltip.material')}</dt>
            <dd>{material?.name}</dd>
            <dt>
              {t('agent.edgebandConfiguration.edgebanding.roundedEdgeband')}
            </dt>
            <dd>{roundedEdgeband ? t('agent.yes') : t('agent.no')}</dd>
          </dl>
        </>
      )}
    </div>
  );
};
