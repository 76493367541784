import {
  useIsMutating,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  agentApi,
  PricingData,
  PricingOverrideData,
} from '@/api/backend/agent';

import { Address } from '../../src/api/address';
import { agentKeys } from './keys';

export const useUpdateCutlistAgent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      id,
      markQuoteReady = false,
    }: {
      id: string;
      markQuoteReady?: boolean;
    }) => agentApi.updateCutlist({ id, markQuoteReady }),
    mutationKey: agentKeys.update,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'cutlist';
        },
        refetchType: 'all',
      });
    },
  });
};

export const useGetPrice = (cutlistId: string) => {
  return useMutation({
    mutationKey: agentKeys.getPrice(cutlistId),
    mutationFn: ({
      id,
      materialGroups,
      discountAmount,
      discountPercentage,
    }: PricingData) =>
      agentApi.getPrice({
        id,
        materialGroups,
        discountAmount,
        discountPercentage,
      }),
  });
};

export const useGetPriceOverride = (cutlistId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: agentKeys.getPriceOverride(cutlistId),
    mutationFn: ({
      id,
      discountAmount,
      discountPercentage,
      pricingLineItems,
      save,
    }: PricingOverrideData) =>
      agentApi.getPriceOverride({
        id,
        discountAmount,
        discountPercentage,
        pricingLineItems,
        save,
      }),
    onSuccess: async (_, variables) => {
      if (variables.save) {
        await queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey[0] === 'cutlist';
          },
          refetchType: 'all',
        });
      }
    },
  });
};

export const useIsPriceLoading = (cutlistId: string) => {
  return useIsMutating({ mutationKey: agentKeys.getPrice(cutlistId) }) > 0;
};

export const useCancelCutlist = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) => agentApi.cancelCutlist(id),
    mutationKey: agentKeys.cancel,
  });
};

export const useAgentUpdateCutlistAddress = () => {
  return useMutation({
    mutationFn: ({ id, address }: { id: string; address: Address }) =>
      agentApi.updateCutlistDeliveryAddress(id, address),
    mutationKey: agentKeys.updateCutlistAddress,
  });
};

export const useAgentFinaliseQuote = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) => agentApi.finaliseQuote(id),
    mutationKey: agentKeys.finaliseQuote,
  });
};
