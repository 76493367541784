import React from 'react';

export const useToggle = (initialValue = false): [boolean, () => void] => {
  const [value, setValue] = React.useState(initialValue);

  const toggle = React.useCallback(() => {
    setValue((v) => !v);
  }, []);

  return [value, toggle];
};

export const useInterval = (
  callback: () => void,
  delay: number,
  callOnMount = true
) => {
  const savedCallback = React.useRef(callback);

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const tick = () => savedCallback.current();

    if (callOnMount) {
      tick();
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

export const useDebounce = <T>(
  value: T,
  delay: number,
  initialValue: T = [] as T
) => {
  const [debouncedValue, setDebouncedValue] = React.useState<T>(initialValue);
  React.useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
};
