import { GetCutlistsParams } from '@/api/backend/agent';

export const cutlistsKeys = {
  create: ['create'],
  update: ['update'],
  getOne: (cutlistId: string) => ['cutlist', cutlistId],
  getAll: ['cutlists'],
  duplicate: ['duplicate'],
  delete: ['delete'],
  acceptQuote: ['acceptQuote'],
};

export const agentKeys = {
  login: ['agent', 'login'],
  getCutlists: (params: GetCutlistsParams) => [
    'agent',
    'cutlists',
    ...Object.keys(params),
  ],
  getPrice: (cutlistId: string) => ['agent', 'price', cutlistId],
  getPriceOverride: (cutlistId: string) => [
    'agent',
    'price',
    'override',
    cutlistId,
  ],
  update: ['agent', 'update'],
  createCutlist: ['agent', 'create'],
  assignCutlistOwner: ['agent', 'update', 'owner'],
  cancel: ['agent', 'cancel'],
  updateCutlistAddress: ['agent', 'update', 'address'],
  finaliseQuote: ['agent', 'finalise', 'quote'],
};
