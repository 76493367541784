import { useIsFetching } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { useLeadDetails } from '@/api/account';
import { ApiError } from '@/api/backend';
import { useCutlistState } from '@/api/store';
import { Button } from '@/primitives/Button';
import { AddUser, Icon, UserAvatar } from '@/primitives/Icons';
import { Input } from '@/primitives/Input';
import { useAssignCutlistOwner } from '@/queries/crud';

import styles from './CutlistOwnerButton.module.css';
import { Modal } from './Modal';

export const CutlistOwnerButton = () => {
  const { id } = useParams();
  const { mutateAsync: assignCutlistOwner } = useAssignCutlistOwner(
    id as string
  );
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { t } = useTranslation();
  const { status } = useCutlistState();
  const { init, email } = useLeadDetails();
  const [loading, setLoading] = React.useState(false);

  const isFetching = useIsFetching();
  if (isFetching) return null;

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      setLoading(true);
      const data = new FormData(e.currentTarget);
      const email = data.get('email');
      const cutlist = await assignCutlistOwner(email as string);
      init({
        ...cutlist.userLeadDetail,
        ...cutlist.userLead,
        notClient: false,
      });
      toast.success(t('agent.setCutlistOwnership.setOwnerSuccess', { email }));
      setLoading(false);
      setIsModalOpen(false);
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        toast.error(error.message);
      } else {
        toast.error('An unexpected error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {status === 'draft' ? (
        <Button
          className="pill"
          variant="header"
          style={{
            background: 'var(--header-cta-background)',
            color: 'var(--header-cta-color)',
            minWidth: 'fit-content',
            pointerEvents: status === 'draft' ? 'auto' : 'none',
          }}
          icon={
            email ? <Icon icon={<UserAvatar />} /> : <Icon icon={<AddUser />} />
          }
          onClick={() => setIsModalOpen(true)}
        >
          <strong>
            {email
              ? t('cutlist-form.owner', { owner: email })
              : t('agent.setCutlistOwnership.setCutlistOwner')}
          </strong>
        </Button>
      ) : (
        <div
          className="flexAlign gap-xs"
          style={{ color: 'var(--header-color)' }}
        >
          <Icon icon={<UserAvatar />} />
          <strong>{t('cutlist-form.owner', { owner: email })}</strong>
        </div>
      )}

      <Modal
        title=""
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        <div className={styles.modalContent}>
          <div>
            <h2>{t('agent.setCutlistOwnership.setCutlistOwner')}</h2>
            <p>{t('agent.setCutlistOwnership.setOwnerInstructions')}</p>
          </div>
          <form
            className={styles.formContainer}
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              onSubmit(e);
            }}
          >
            <div>
              <label htmlFor="email">
                {t(`agent.setCutlistOwnership.email`)} {'*'}
              </label>
              <Input type="email" name="email" required />
            </div>
            <Button type="submit" disabled={loading}>
              {t('agent.setCutlistOwnership.setOwner')}
            </Button>
          </form>
        </div>
      </Modal>
    </>
  );
};
