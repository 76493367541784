import { CutlistOrder } from '@cutr/constants/cutlist';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button, RouterButton } from '@/primitives/Button';
import { Duplicate, Icon, Trash } from '@/primitives/Icons';
import { currencyFormatter, dateFormatter } from '@/utils/format';

import styles from './OrderCard.module.css';

type OrderCardProps = {
  cutlist: CutlistOrder;
  onDuplicate: (id: string) => void;
  onDelete: (id: string) => void;
};

export const OrderCard = ({
  cutlist,
  onDuplicate,
  onDelete,
}: OrderCardProps) => {
  const { t } = useTranslation();

  const getDateLabel = (cutlist: CutlistOrder) => {
    if (cutlist.submittedAt) {
      return `${t('cutlists.submitted')} ${dateFormatter(cutlist.submittedAt)}`;
    }

    return `${t('cutlists.created')} ${dateFormatter(cutlist.createdAt)}`;
  };

  const isDraft = !cutlist.submittedAt && !cutlist.cancelledAt;
  const priceFormatted = currencyFormatter(cutlist.totalAmountExclVAT / 100);

  return (
    <article className={styles.article}>
      <div className={styles.stack}>
        <h3>{cutlist.title}</h3>
        <span data-cy="cutlist-short-id">{cutlist.shortId}</span>
        {cutlist.userLeadDetail?.companyName && (
          <span>
            {`${cutlist.userLeadDetail.companyName}` +
              (cutlist.userLead?.clientNumber
                ? ` (${cutlist.userLead?.clientNumber})`
                : '')}
          </span>
        )}
        <span>
          {cutlist.userLeadDetail?.name && cutlist.userLeadDetail.name}
        </span>
        <span>{cutlist.userLead?.email && cutlist.userLead.email}</span>
      </div>

      <div className={styles.stack}>
        <h3>{t(`cutlists.${cutlist.status}`)}</h3>
        <span>{getDateLabel(cutlist)}</span>
      </div>

      <div className={cn(styles.stack, styles.alignRight)}>
        <h3>
          {cutlist.manualQuote
            ? t('cutlists.manualQuotePrice', {
                price: priceFormatted,
              })
            : priceFormatted}
        </h3>
        <RouterButton
          data-cy="view-cutlist"
          to={
            isDraft ? `/cutlist/${cutlist.id}/parts` : `/cutlist/${cutlist.id}`
          }
          variant="secondary"
          className="full"
        >
          {t('cutlists.view')}
        </RouterButton>

        <div className={cn('flexAlign', styles.column, 'gap-xs')}>
          <Button
            icon={<Icon icon={<Duplicate />} />}
            variant="secondary"
            className="full"
            onClick={() => onDuplicate(cutlist.id)}
          >
            {t('cutlists.actions.duplicate')}
          </Button>

          {isDraft && (
            <Button
              icon={<Icon icon={<Trash />} />}
              variant="secondary"
              className="full delete"
              onClick={() => onDelete(cutlist.id)}
            >
              {t('cutlists.actions.delete')}
            </Button>
          )}
        </div>
      </div>
    </article>
  );
};
