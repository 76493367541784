import { Material } from '@cutr/constants/cutlist';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { getMaterial } from '@/api/materials';
import { useMaterialGroupState } from '@/api/materialsGroup';
import { AgentOrderHeader } from '@/blocks/AgentOrderHeader';
import Card from '@/blocks/Card';
import { materialDimensions } from '@/blocks/MaterialRow';
import { AgentQuotingPartTable } from '@/interfaces/AgentQuotingPartTable';
import { EDGE_PROCESSING_FORM_ID } from '@/interfaces/AgentQuotingPartTable/EdgeProcessingConfiguration';
import { MaterialGroupTabNav } from '@/interfaces/MaterialGroupTabNav';
import { Nesting } from '@/interfaces/Nesting';
import { Options } from '@/interfaces/PartTableCommon/Components';
import { Button } from '@/primitives/Button';
import { Download, Icon } from '@/primitives/Icons';
import { useUpdateCutlistAgent } from '@/queries/agent';

import styles from './AgentQuoteParts.module.css';

const isProduction = process.env.NODE_ENV === 'production';

export const AgentQuoteParts = (): JSX.Element => {
  return (
    <>
      <AgentOrderHeader />
      <SubHeader />
      <CutlistEmailOrder />
      <MaterialGroupTabNav />
      <section className="layout">
        <AgentQuotingPartTable />
        <Aside />
      </section>
      {/* forms cannot be nested, so we need another form for edge processing */}
      <form id={EDGE_PROCESSING_FORM_ID} name={EDGE_PROCESSING_FORM_ID}></form>
    </>
  );
};

const CutlistEmailOrder = () => {
  return <div></div>;
};

export const SubHeader = () => {
  return <Options />;
};

const Aside = () => {
  return (
    <aside>
      <MaterialSummary />
      <Nesting hidden={isProduction} />
    </aside>
  );
};

type MaterialSummary = {
  id: string;
  core1: Material;
};

const MaterialSummary = () => {
  const { t } = useTranslation();
  const materialGroups = useMaterialGroupState((state) => state.groups);

  const core1Materials = React.useMemo(() => {
    return materialGroups
      .map(
        (group) =>
          ({
            id: nanoid(),
            core1: getMaterial(group?.core1),
          } as MaterialSummary)
      )
      .filter((item) => item.core1);
  }, [materialGroups]);

  return (
    <Card>
      <div className={styles.divider}>
        <h3>{t('agent.quoteFlow.materialSummaryTitle')}</h3>

        {core1Materials.length > 0 ? (
          <ol className={styles.materialSummary}>
            {core1Materials.map(({ id, core1 }) => {
              const itemDimensions = materialDimensions(core1);
              return (
                <li key={id}>
                  <div className="stack gap-xxs">
                    <span>{core1.name}</span>
                    <small className="textStrong">{itemDimensions}</small>
                  </div>
                </li>
              );
            })}
          </ol>
        ) : (
          <p>{t('agent.quoteFlow.noMaterials')}</p>
        )}
      </div>

      <ActionButtons />
    </Card>
  );
};

const ActionButtons = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const { mutateAsync: updateCutlist } = useUpdateCutlistAgent();

  if (!id) {
    return null;
  }

  const onAction = async () => {
    setIsLoading(true);
    await updateCutlist({ id }).finally(() => {
      setIsLoading(false);
    });
    navigate(`/admin/orders/${id}/quote/price`);
  };

  const downloadHpo = () => {
    console.log('downloadHpo');
  };

  return (
    <div className="stack" style={{ paddingTop: '1rem' }}>
      <Button
        className="outline"
        icon={<Icon icon={<Download />} />}
        onClick={downloadHpo}
      >
        {t('agent.quoteFlow.downloadHpo')}
      </Button>
      <Button onClick={onAction} disabled={isLoading}>
        {t('agent.quoteFlow.partsCallToAction')}
      </Button>
    </div>
  );
};
